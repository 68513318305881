<template>
    <div class="flex flex-col gap-6 justify-between flex-1">
        <Card title="License Details" titleClass="text-white-text">
            <div v-if="loadingStates.fetchingDetails" class="py-20 flex justify-center items-center bg-white rounded-lg" hideTitle>
                <Loader />
            </div>
            <div v-else class="grid grid-cols-3 gap-x-4 w-full p-4">
                <FormulateInput class="w-full disableInput" v-model="licenseData.licenseDetails.license_pack_name" label="License Name" placeholder="License Name" type="text" validation="required" :element-class="(context, classes) => ['flex-1 min-w-full bg-white'].concat(classes)" disabled/>

                <FormulateInput class="w-full disableInput" v-model="licenseData.licenseDetails.description" label="License Description" placeholder="Enter Description" type="text" :element-class="(context, classes) => ['flex-1 min-w-full bg-white'].concat(classes)" disabled/>

                <div class="flex flex-col gap-1">
                    <label>Validity in Days</label>
                    <div class="flex justify-center items-center h-10 border rounded-md disableInput">
                        <div class="bg-primary-300 text-white-text h-full rounded-l-md flex items-center justify-center px-4">Days</div>
                        <input type="text" v-model="licenseData.licenseDetails.validity" class="flex-1 px-4 w-full h-full rounded-r-lg text-md text-gray-700" placeholder="Validity in Days"  disabled/>
                    </div>
                </div>
                <FormulateInput class="w-full disableInput" v-model="licenseData.licenseDetails.tenant_license_count" label="Number of License" placeholder="Number of License" type="text" :element-class="(context, classes) => ['flex-1 min-w-full bg-white'].concat(classes)" disabled/>
                <FormulateInput class="w-full disableInput" v-model="licenseData.licenseDetails.alloted_license_count" label="Allotted License" placeholder="Allotted License" type="text" :element-class="(context, classes) => ['flex-1 min-w-full bg-white'].concat(classes)" disabled/>
                <FormulateInput class="w-full disableInput" v-model="licenseData.licenseDetails.balanced_license_count" label="Balanced License" placeholder="Balanced License" type="text" :element-class="(context, classes) => ['flex-1 min-w-full bg-white'].concat(classes)" disabled/>
               
            </div>
        </Card>
    </div>
</template>

<script>
import Card from "@shared/components/card";
import Loader from "@shared/loader";

export default {
    name: "license-info-card",
    components: {
        Card,
        Loader,
    },
    props: {
        licenseData: {
            type: Object,
            default: () => {},
        },
        loadingStates: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {};
    },
    computed: {},
    async mounted() {},
    methods: {
       
    },
};
</script>

<style lang="scss" scoped>
    ::v-deep {
    .disableInput {
        input {
            background: #f9fafb !important;
            cursor: not-allowed;
        }
    }
}
</style>
